import '../App.css'
import img1 from '../imgs/img-dent1.jpg'
export default function Header() {
  return (
    <div className="container_dividido">
     

        <div className="content1">

<div className="portada1">
 <h1>PERFECCIONA TU <span className='frase_special1'>SONRISA</span>✨ CON NOSOTROS</h1>
</div>
{/* <img src={img1}  alt="" /> */}
<p>toque aquí abajo para comunicarse con el doctor encargado por whatsapp</p>
<a target={'_blank'} href="https://api.whatsapp.com/send/?phone=59173452187&text&app_absent=0" rel="noreferrer">
<button id='btn_whatsapp'>whatsapp</button>
</a>
      <br /><br /><br />
      </div>

        {/* CONTENIDO #2 */}
        <div className="content2">
        
        <img src={img1}  alt="" />
        <p>Nuestra dirección es en Av.H. Vasquez,altura cancha Santa Barbara,altura cancha santa barbara</p>
        <p>toque el mapa de abajo para ingresar a nuestra dirección en google maps</p>
        </div>
        <br />
        <div className="mapa">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3758.8051745823427!2d-'65'.75935788680756!3d-19.592845686793492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93f94fac7b1e7821%3A0x2b90541ebefe0d1!2sTecno-dent%20Potos%C3%AD!5e0!3m2!1ses-419!2sbo!4v164100%9245197!5m2!1ses-419!2sbo"  
        style={{width:'100%',border:'0',height:'100%'}} 
        allowFullScreen="" loading="lazy" title='google-maps'
        referrerPolicy="no-referrer-when-downgrade"/>
        </div>
        <br />
        <div className="footer">
          <div id='footer_1'>Dirección:Av.H. Vasquez,altura cancha <br /> Santa Barbara,altura cancha santa barbara</div>
          <div id="footer_2">
            para una mejor atención le recomendamos llamar al 
            <code> celular:73452187</code></div>
          
          <div id='footer_3'>© Copyright victor code  All Rights Reserved <br />
             creado por <span id='author'><a target={'_blank'} rel="noreferrer" href="http://victor-code.vercel.app/links">@victor code</a></span></div>
    </div>
    </div>
  )
}
