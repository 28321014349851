import Header from "../Header";
import Modal from "../Modal/Modal";
import Navbar from "../Navbar";
export function AppContent(){
    return(<div>
        <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <Navbar />
      </div>
      <Modal />
      <Header />
    </div>);
}