import {React,useState} from 'react'
import './Modal.css'
import imgDental from '../../imgs/img-for-pop-up-for-web.webp'

export default function Modal() {
    const [modal,setModal]=useState(true)
    const toggleModal=()=>{
        setModal(!modal);
    }
    if (modal) {
        document.body.classList.add('active_modal')
    }
    else
    {
        document.body.classList.remove('active_modal')
    }
  return (
    <>
    <button onClick={toggleModal} className="btn-modal">
      toque aquí para ver publicidad
    </button>

    {modal && (
      <div className="modal">
        <div onClick={toggleModal} className="overlay"></div>
        <div className="modal-content">
          <br />
          {/* <h2>Aquí en nuestra clínica</h2> */}
          <br />
         
          <img src={imgDental} alt="imgDental" />
          {/* <p>
          tecnodent es su mejor opción
          </p> */}
          <button className="close-modal" onClick={toggleModal}>
            CERRAR
          </button>
          
        </div>
      </div>
    )}
    
  </>
  )
}
