import img1 from '../imgs/marca-dental1.webp'
export default function Navbar() {
  return (
    <div className="navbar" >
      <div className="children_navbar">
     <a href='#!'>
        <img style={{width:'87px'}} src={img1} alt="marca" /></a>
      </div>
      
        <div className="children_navbar">
        <a rel="noreferrer" target={"_blank"} href="https://g.page/Tecnodent-potosi?share">dirección</a>
        </div>

        <div className="children_navbar">
        <a href="https://wa.me/59173452187" rel="noreferrer" target={"_blank"}>whatsapp</a>
        </div>

    </div>
  )
}
