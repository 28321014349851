import { useEffect, useState } from "react";
import "./App.css";

import Loader from "./Components/Loader/Loader";
import { AppContent } from "./Components/AppContainer/AppContent";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
    console.log("apareciendo");
    setTimeout(() => {
      setIsLoaded(false);
      console.log("desapareciendo-...App.");
    }, 3000);
  }, []);
  return (
    <div className="container">{isLoaded ? <Loader /> : <AppContent />}</div>
  );
}

export default App;
